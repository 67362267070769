import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { jsPDF } from 'jspdf';

function SubmissionDetails(props) {
  function downloadDetailPDF() {
    const doc = new jsPDF('portrait', 'px');

    doc.setFontSize(18);

    const html = printableHtml();
    const htmlString = ReactDOMServer.renderToStaticMarkup(html);

    doc.addImage('/assets/logo-1.png', 'PNG', 75, 10, 300, 50);

    doc.html(htmlString, {
      callback: function (doc) {
        doc.save(`${getNameFromAnswers()}-${new Date().toISOString()}-submission.pdf`);
      },
      margin: [0, 10, 0, 10],
      html2canvas: {
        scale: 0.5,
        autoPaging: 'text',
        scrollX: 0,
        scrollY: 0,
        windowWidth: 750,
        windowHeight: 1000
      },
      y: 75
    });
  }

  function printableHtml() {
    return (
      <div className="container" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div className="row" style={{ width: 750, marginBottom: 10 }}>
          <h1>{getNameFromAnswers()}</h1>
          <h5>{getEmailFromAnswers()}</h5>
        </div>

        <div style={{ marginTop: 25, width: 750 }}>
          <h4>{props.Submission.FormTitle}</h4>
        </div>

        <div className="row" style={{ width: 750, pageBreakAfter: 'always', marginTop: 25 }}>
          <h4>Required Documents</h4>
          <div style={{ marginLeft: 15 }}>{getDocs()}</div>
        </div>

        <div className="row" style={{ width: 750, pageBreakBefore: 'always', marginTop: 25 }}>
          <h4>Questions and Answers</h4>

          <div style={{ marginLeft: 15 }}>{getQuestionsAndAnswers()}</div>
        </div>
      </div>
    );
  }

  function getBusinessLegalFromAnswers() {
    const list = props.Submission.Questions.filter(q => q.Text == "Business Legal name");

    if (list && list.length == 1)
      return list[0].Answer;
    else
      return "-N/A-";
  }

  function getNameFromAnswers() {
    const businessName = getBusinessLegalFromAnswers();

    if (businessName && businessName !== "-N/A-")
      return businessName;
    
    const list = props.Submission.Questions.filter(q => q.Text == "First Name" || q.Text == "Last Name");

    if (list && list.length == 2) {
      var str = " ";

      list.forEach(element => {
        if (element.Text == "First Name") {
          str = element.Answer + str;
        } else {
          str = str + element.Answer;
        }
      });

      return str;
    } else {
      const fullName = props.Submission.Questions.filter(q => q.Text == "Name of Submitter");

      if (fullName && fullName.length == 1) {
        return fullName[0].Answer;
      } else {
        return "-N/A-";
      }
    }
  }

  function getEmailFromAnswers() {
    const list = props.Submission.Questions.filter(q => q.Text == "Email");

    if (list && list.length == 1)
      return list[0].Answer;
    else
      return "-N/A-";
  }

  function getQuestionsAndAnswers() {
    var answered = props.Submission.Questions.filter(q => q.Answer);

    return answered.map((q, i) => {
      return <>
        <b>{q.Text}</b>
        <p>{q.Answer.toString()}</p>
        { i < answered.length - 1 ? <hr style={{ marginTop: 8, marginBottom: 8 }} /> : null }
      </>
    })
  }

  function getDocs() {
    if (!props.Submission.Documents || props.Submission.Documents.length === 0) {
      return <p>None</p>
    }
  
    return props.Submission.Documents.map((doc, i) => {
      const isReceived = props.Submission.ReceivedDocuments && 
                        props.Submission.ReceivedDocuments.includes(doc);
  
      return (
        <div key={i} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <input 
            type="checkbox"
            style={{ cursor: 'pointer' }}
            checked={isReceived}
            onChange={() => handleDocumentCheck(doc, !isReceived)}
            title={isReceived ? "Mark as not received" : "Mark as received"}
          />
          <p dangerouslySetInnerHTML={{ __html: doc }}></p>
          {i < props.Submission.Documents.length - 1 ? 
            <hr style={{ marginTop: 8, marginBottom: 8 }} /> : 
            null
          }
        </div>
      )
    })
  }
  
  function handleDocumentCheck(document, isChecked) {
    if (props.onDocumentReceived) {
      props.onDocumentReceived(document, isChecked);
    }
  }  

  function getLastUpdateTime() {
    if (props.Submission.LastStatusUpdate) {
      return props.Submission.LastStatusUpdate.toDate().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      });
    } else {
      return props.Submission.Timestamp.toDate().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      });
    }
  }

  function getStatus() {
    if (!props.usesStatus) {
      return null;
    }
    
    var statusColor = {};

    if (props.Submission.Status == "Complete") {
      statusColor.color = "var(--green)";
    } else {
      statusColor.color = "var(--yellow)";
    }

    return <span><span style={{ ...statusColor }}>{props.Submission.Status}</span> - </span>;
  }

  function render() {
    return (
      <div className="container" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div className="row" style={{ height: 200 }}>
          <h1>
            {getNameFromAnswers()} - {getEmailFromAnswers()}
            <span title="Download Submission Details" style={{ fontSize: 25, float: "right", cursor: 'pointer' }} className="bi-download" onClick={() => {
              downloadDetailPDF();
            }}></span>
          </h1>
          <h5>{props.Submission.FormTitle}</h5>
          <h4>{getStatus()}{getLastUpdateTime()}</h4>
        </div>


        <div className="row" style={{ height: 'calc(100% - 200px)' }}>
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button className="nav-link active" id="nav-qa-tab" data-bs-toggle="tab" data-bs-target="#nav-qa" type="button" role="tab" aria-controls="nav-qa" aria-selected="true">Answers</button>
              <button className="nav-link" id="nav-docs-tab" data-bs-toggle="tab" data-bs-target="#nav-docs" type="button" role="tab" aria-controls="nav-docs" aria-selected="false">Required Documents</button>
            </div>
          </nav>

          <div className="tab-content" id="nav-tabContent" style={{ padding: 20, paddingBottom: 0, overflowY: 'scroll', height: 'calc(100% - 50px)' }}>
            <div className="tab-pane fade show active" id="nav-qa" role="tabpanel" aria-labelledby="nav-qa-tab">{getQuestionsAndAnswers()}</div>
            <div className="tab-pane fade" id="nav-docs" role="tabpanel" aria-labelledby="nav-docs-tab">{getDocs()}</div>
          </div>
        </div>
      </div>
    );
  }

  return render();
}

const styles = {
}

export default SubmissionDetails;