import React from 'react';
import { initializeApp } from '@firebase/app';
import { getFirestore, getDocs, query, collection, where, writeBatch, doc, getDoc } from '@firebase/firestore';
import { toastPromise } from '../Helpers/ToastMessage';
import SystemCompanyOptions from './SystemCompanyOptions';
import { getFunctions, httpsCallable } from "firebase/functions";
import RuleForm from './RuleForm';
import { AgGridReact } from 'ag-grid-react';
import { useCompany, useScreens } from '../Store/storeFunctions';
import { BeatLoader } from 'react-spinners';
import SystemSubmissions from './SystemSubmissions';

const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const db = getFirestore(app);
const functions = getFunctions();
const sendCompanyInviteEmail = httpsCallable(functions, "sendCompanyInviteEmail");
const searchPortPhoneNumbersEligibility = httpsCallable(functions, "searchPortPhoneNumbersEligibility");

function SystemAdmin(props) {
  const [companies, setCompanies] = React.useState();
  const [createCompany, setCreateCompany] = React.useState();
  const [tempEmail, setTempEmail] = React.useState();
  const [tempPhone, setTempPhone] = React.useState();
  const [gridHeight, setGridHeight] = React.useState();

  const company = useCompany();
  const screens = useScreens();

  const gridRef = React.useRef();

  function updateSize() {
    setGridHeight(window.innerHeight - 325);
  }

  const columnDefs = [
    {
      field: 'name',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "Company Name."
    },
    {
      field: 'Company Code',
      valueGetter: (params) => {
        return params.data.id;
      },
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'Using Sharing',
      valueGetter: (params) => {
        return params.data.extraFeatures?.sharableStatus ? "Yes" : "";
      },
      resizable: true,
      sortable: true,
      filter: true,
    },
    {
      field: 'Using Messaging',
      valueGetter: (params) => {
        return params.data.extraFeatures?.messaging ? "Yes" : "";
      },
      resizable: true,
      sortable: true,
      filter: true,
    },
    {
      field: 'Using Admin Assignment',
      valueGetter: (params) => {
        return params.data.extraFeatures?.assignAdmins ? "Yes" : "";
      },
      resizable: true,
      sortable: true,
      filter: true,
    },
    {
      field: 'Own Outgoing Email',
      valueGetter: (params) => {
        return !!params.data.email && !!params.data.emailKey ? "Yes" : "";
      },
      resizable: true,
      sortable: true,
      filter: true,
    },
    {
      field: 'Subscription Status',
      valueGetter: (params) => {
        return params.data.stripeSubscriptionStatus;
      },
      resizable: true,
      sortable: true,
      filter: true,
    },
    {
      field: 'Total Submissions',
      valueGetter: (params) => {
        return params.data.yearlyAllotmentProgress ?? "";
      },
      resizable: true,
      sortable: true,
      filter: true,
    },
    {
      field: 'Messaging Status',
      valueGetter: (params) => {
        return getCompanyTwilioStatus(params.data);
      },
      resizable: true,
      sortable: true,
      filter: true,
    },
    {
      field: 'Messaging Number',
      valueGetter: (params) => {
        return getCompanyTwilioNumber(params.data);
      },
      resizable: true,
      sortable: true,
      filter: true,
    },
  ];

  React.useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();

    if (company.companyData?.code && company.companyData?.code == 'tcpSystemAdministration' && !companies) {
      getCompanies();
    }
  }, []);

  function getCompanies() {
    toastPromise(async () => {
      var actives = [];
      var c = await getDocs(query(collection(db, '/BusinessRules')));

      c.forEach((doc) => {
        if (doc.exists()) {
          actives.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });
      
      setCompanies(actives);
    }, true);
  }

  function createNewCompany() {
    setCreateCompany(true);
    screens.clearSubScreens();
  }

  function sendInvitation() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      const c = rows[0];
      toastPromise(async () => {
        sendCompanyInviteEmail({
          company: c,
          emailAddresses: tempEmail,
          id: c.id
        });
      });
    }
  }

  function completedSystemCompanyEdit(){
    setCreateCompany(false);
    screens.clearSubScreens();
    getCompanies();
  }

  function viewSubmissions() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      screens.setSubScreen({
        content: rows[0].id,
        data: rows[0].id,
        type: "CompanySubmissions"
      });
    }
  }

  function editOptions() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      screens.setSubScreen({
        content: rows[0].id,
        data: rows[0].id,
        type: "EditOptions"
      });
    }
  }

  function editForm() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      screens.setSubScreen({
        content: rows[0].id,
        data: rows[0].id,
        type: "EditForms"
      });
    }
  }

  function testHostedEligibility() {
    if (tempPhone) {
      const testPhone = `+1${tempPhone.replace(/\D/g,'')}`;

      toastPromise(async () => {
        var results = await searchPortPhoneNumbersEligibility({
          phone_numbers: [
            { phone_number: testPhone }
          ]
        });
        
        console.log(results);

        if (results?.data?.results?.length == 1) {
          var message = "";
          
          message += `Number: ${results.data.results[0].phone_number} - ${results.data.results[0].phone_number_type}\r\n`;
          message += `Status: ${results.data.results[0].eligibility_status} - ${results.data.results[0].eligibility_sub_status}\r\n`;
          message += `Reason: ${results.data.results[0].ineligibility_reason}\r\n`;
          message += `Next Step: ${results.data.results[0].next_step}\r\n`;

          alert(message);
        }
      });
    }
  }

  function getCompanyTwilioNumber(c) {
    return c.twilio?.phoneNumber ?? "";
  }

  function getCompanyTwilioStatus(c) {
    if (!c.extraFeatures?.messaging) {
      return "";
    } else if (!c.twilio?.sid) {
      return "Needs Sub Account";
    } else if (!c.twilio?.phoneNumber) {
      return "Needs phone number";
    } else if (!c.twilio?.verified) {
      return c.twilio?.verificationStatus && c.twilio?.verificationDescription ? `${c.twilio.verificationStatus} - ${c.twilio.verificationDescription}` : "Needs verification";
    }

    return "Already set up";
  }

  function renderCompanies() {
    const temp = (
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Twilio</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            companies.map(c => {
              return (
                <tr>
                  <td style={{ textAlign: 'left' }}>{c.id}</td>

                  <td>{getCompanyTwilioStatus(c)}</td>

                  <td>
                    
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    );


    return <div style={{ height: gridHeight, width: '100%', position: 'relative', marginTop: 12 }} className="ag-theme-balham">
      <AgGridReact
        ref={gridRef}
        rowData={companies}
        columnDefs={columnDefs}
        suppressRowClickSelection={true}
        enableCellTextSelection={true}
        suppressMenuHide={true}
        rowSelection="single">
      </AgGridReact>
    </div>
  }

  function viewCompanySubmissions() {
    if (screens.state.currentSubScreens.length > 0 && screens.state.currentSubScreens[0].type == "CompanySubmissions") {
      return screens.state.currentSubScreens[0].data;
    } else {
      return undefined;
    }
  }

  function editCompany() {
    if (screens.state.currentSubScreens.length > 0 && screens.state.currentSubScreens[0].type == "EditOptions") {
      return screens.state.currentSubScreens[0].data;
    } else {
      return undefined;
    }
  }

  function editFormCompany() {
    if (screens.state.currentSubScreens.length > 0 && screens.state.currentSubScreens[0].type == "EditForms") {
      return screens.state.currentSubScreens[0].data;
    } else {
      return undefined;
    }
  }

  function render() {
    if (createCompany || editCompany()) {
      return <SystemCompanyOptions createCompany={createCompany}
        companies={companies}
        company={editCompany()}
        back={()=> {
          setCreateCompany(false);
          screens.clearSubScreens();
        }}
        finished={completedSystemCompanyEdit} />
    } else if (editFormCompany()) {
      return <RuleForm company={editFormCompany()} isSystem={true} editOptions={props.editOptions} editAdmins={props.editAdmins} viewSubmissions={props.viewSubmissions} />
    } else if (viewCompanySubmissions()) {
      return <SystemSubmissions company={viewCompanySubmissions()} />
    }

    if (!companies) {
      return (<div className="loadingSpinner"><BeatLoader color={"#045AA1"} /></div>);
    }

    return (
      <div className="container-fluid" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 10 }}>
          <input type="button" className="btn btn-sm btn-success" value="Create New Company" onClick={createNewCompany} />

          <div className="input-group" style={{ width: 500 }}>
            <input type="tel" className="form-control form-control-sm" id="phone" value={tempPhone} onChange={e => setTempPhone(e.target.value)} placeholder="Hosted SMS Phone Number" />
            <button type="button" className="btn btn-sm btn-primary" onClick={testHostedEligibility}>Check Eligibility</button>
          </div>

          <div className="input-group" style={{ width: 500 }}>
            <input type="email" className="form-control form-control-sm" id="email" value={tempEmail} onChange={e => setTempEmail(e.target.value)} placeholder="Enter invitation email" />
            <button type="button" className="btn btn-sm btn-primary dropdown-toggle" data-bs-toggle="dropdown">Select Action</button>
            
            <ul className="dropdown-menu" role="menu">
              <li><a className="dropdown-item" onClick={editOptions}>Edit Options</a></li>
              <li><a className="dropdown-item" onClick={editForm}>Form Editor</a></li>
              <li><a className="dropdown-item" onClick={viewSubmissions}>View Submissions</a></li>
              <li><a className="dropdown-item" onClick={sendInvitation} title={`Send invite to ${tempEmail}`}>Invite</a></li>
            </ul>
          </div>
        </div>

        {renderCompanies()}
      </div>
    );
  }

  return render();
}

export default SystemAdmin;