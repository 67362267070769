import React from 'react';
import { initializeApp } from '@firebase/app';
import { getFirestore, getDocs, query, where, collection, Timestamp } from '@firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import DateTimePicker from 'react-datetime-picker';
import { toastPromise } from '../Helpers/ToastMessage';
import { useCompany, useUser } from '../Store/storeFunctions';
import { AgGridReact } from 'ag-grid-react';
import { AgeCellRenderer, ViewDetailsCellRenderer, YesNoCellRenderer } from '../Helpers/AgGridCellRenderers';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import ResendSubmissions from '../Helpers/ResendSubmissions.tsx';
import Modal from "react-modal";
import SubmissionDetails from './SubmissionDetails';

const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const db = getFirestore(app);
const functions = getFunctions();
const processSubmission = httpsCallable(functions, "resendSubmissionEmail");
const updateStatuses = httpsCallable(functions, "updateStatus");
const updateAssignee = httpsCallable(functions, "updateAssignee");
const updatedReceivedSubmissionDocs = httpsCallable(functions, "updateReceivedDocs");

function Submissions() {
  const [submissions, setSubmissions] = React.useState();
  const [showResend, setShowResend] = React.useState(false);
  const [testFromDate, setTestFromDate] = React.useState(getInitialFromDate());
  const [testToDate, setTestToDate] = React.useState(getInitialToDate());
  const [gridHeight, setGridHeight] = React.useState();
  const [newStatus, setNewStatus] = React.useState();
  const [assignedAdmin, setAssignedAdmin] = React.useState();
  
  const [viewDetailSubmission, setViewDetailSubmission] = React.useState();

  const gridRef = React.useRef();

  const company = useCompany();
  const user = useUser();

  React.useEffect(() => {
     window.addEventListener('resize', updateSize);
     updateSize();
     
     if (!company.admins && company.companyData.code) {
      company.loadAdmins(company.companyData.code);
     }
  }, []);

  function updateSize() {
    setGridHeight(window.innerHeight - 325);
  }

  function getInitialFromDate() {
    var d = new Date();
    d.setDate(d.getDate() - 21);
    d.setHours(0, 0, 0);
    return d;
  }

  function getInitialToDate() {
    var d = new Date();
    d.setHours(23, 59, 59);
    return d;
  }
  
  function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  function viewSubmissionDetails(id) {
    if (submissions && id) {
      var subs = submissions.filter(s => s.id === id);

      if (subs.length == 1) {
        setViewDetailSubmission(subs[0]);
      }
    }
  }

  const columnDefs = [
    {
      field: 'name',
      valueGetter: (params) => {
        return {
          id: params.data.id,
          value: getNameFromAnswers(params.data.Questions)
        }
      },
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "Client name.",
      cellRenderer: ViewDetailsCellRenderer,
      cellEditorParams: {
        viewDetails: viewSubmissionDetails
      }
    },
    {
      field: 'Age',
      valueGetter: (params) => {
        if (params.data.Status == "Complete") {
          return null; // age does not apply
        }

        if (params.data.LastStatusUpdate) {
          var updated = dateDiffInDays(params.data.LastStatusUpdate.toDate(), new Date());

          return updated;
        } else {
          var processed = dateDiffInDays(params.data.Timestamp.toDate(), new Date());

          return processed;
        }
      },
      cellRenderer: AgeCellRenderer,
      resizable: true,
      sortable: true,
      width: 75,
      headerTooltip: "Time since the last status update. Green = young, red = old.",
      hide: company.companyData?.extraFeatures?.sharableStatus != true,
      lockVisible: true
    },
    {
      field: 'Status',
      valueGetter: (params) => {
        return params.data.Status ?? "Submit Docs";
      },
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "Current status of this submission.",
      hide: company.companyData?.extraFeatures?.sharableStatus != true,
      lockVisible: true
    },
    {
      field: 'FormTitle',
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "Title of the form used."
    },
    {
      field: 'email',
      valueGetter: (params) => {
        return getEmailFromAnswers(params.data.Questions);
      },
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "Client email address."
    },
    {
      field: 'phone',
      valueGetter: (params) => {
        return getMobileNumberFromAnswers(params.data.Questions);
      },
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "Client phone number."
    },
    {
      field: 'AssignedPreparer',
      valueGetter: (params) => {
        return params.data.AssignedPreparer || "-";
      },
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "Assigned Preparer",
      hide: company.companyData?.extraFeatures?.assignAdmins != true
    },
    {
      field: 'Processed',
      valueGetter: (params) => {
        if (params.data.Processed) {
          return "YES";
        } else {
          return "NO";
        }
      },
      cellRenderer: YesNoCellRenderer,
      resizable: true,
      sortable: true,
      filter: true,
      width: 125,
      headerTooltip: "Initial document checklist was sent."
    },
    {
      field: 'Timestamp',
      valueGetter: (params) => {
        return params.data.Timestamp.toDate();
      },
      valueFormatter: (params) => {
        return params.data.Timestamp.toDate().toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        });
      },
      resizable: true,
      sortable: true,
      headerTooltip: "Time the last document checklist was sent."
    }
  ];

  React.useEffect(() => {
    getCompanySubmissions();
  }, []);

  function getTimestampWithoutTimezone(value) {
    // TODO: Later - dates are currently being stored with the timezone.
    // change this back when we are done storing dates without timezone.
    // const date = new Date(value);
    // const offset = date.getTimezoneOffset() * 60000;
    // const normal = new Date(date.getTime() + offset);
    return Timestamp.fromDate(value);
  }

  function getCompanySubmissions(e) {
    if (e)
      e.preventDefault();
    
    toastPromise(
      async () => { 
        if (testFromDate && testToDate) {
          const from = getTimestampWithoutTimezone(testFromDate);
          const to = getTimestampWithoutTimezone(testToDate);

          var subs = [];
          let submissionQuery = query(
            collection(db, `/BusinessRules/${company.companyData?.code}/Submissions`),
            where('Timestamp', ">=", from),
            where('Timestamp', "<=", to)
            );

          var submissionDocs = await getDocs(submissionQuery);

          submissionDocs.forEach((doc) => {
            if (doc.exists()) {
              var sub = 
              {
                id: doc.id,
                ...doc.data()
              };

              if (sub.Questions) {
                sub.name = getNameFromAnswers(sub.Questions);
                sub.email = getEmailFromAnswers(sub.Questions);
              } else {
                sub.name = "";
                sub.email = "";
              }

              subs.push(sub);
            }
          });

          setSubmissions(subs);
        }
      },
      true
    );
  }

  async function resendEmail() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      toastPromise(
        async ()=>{
          for (let i = 0; i < rows.length; i++) {
            var row = rows[i];

            var res = await processSubmission({
              submissionID: row.id,
              formID: row.FormID,
              companyID: company.companyData?.code,
            });
            
            if (!res.data.success) {
              throw 'Email did not send.';
            }
          }
        },
        false,
        `Sending...`,
        `Emails Sent!`
      );
    }
  }

  async function resendAdminEmail() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      toastPromise(
        async ()=>{
          for (let i = 0; i < rows.length; i++) {
            var row = rows[i];
            
            var res = await processSubmission({
              submissionID: row.id,
              formID: row.FormID,
              companyID: company.companyData?.code,
              to: user.user.email
            });

            if (!res.data.success) {
              throw 'Email did not send.';
            }
          }
        },
        false,
        `Sending to ${user.user.email}...`,
        `Email Sent to ${user.user.email}!`
      );
    }
  }

  async function resendNewEmail(tempEmail) {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      if (tempEmail) {
        toastPromise(
          async ()=>{
            for (let i = 0; i < rows.length; i++) {
              var row = rows[i];
              
              var res = await processSubmission({
                submissionID: row.id,
                formID: row.FormID,
                companyID: company.companyData?.code,
                to: tempEmail
              });

              if (!res.data.success) {
                throw 'Email did not send.';
              }
            }
          },
          false,
          `Sending to ${tempEmail}...`,
          `Email Sent to ${tempEmail}!`
        );
      } else {
        alert("Custom email must be entered in order to 'Send to Custom'. Please fill in the Custom Email field and try again.");
      }
    }
  }

  async function statusUpdate() {
    if (!newStatus) {
      console.error("No status selected.");
      return;
    }

    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      toastPromise(
        async ()=>{
          var updateData = {
            CompanyID: company.companyData?.code,
            SubmissionIDs: rows.map(r => r.id),
            NewStatus: newStatus
          };

          var res = await updateStatuses(updateData);

          if (!res.data) {
            throw 'Could not update at this time.';
          }

          await getCompanySubmissions();
        },
        false,
        `Updating status...`,
        `Successfully updated!`
      );
    }
  }

  async function AssignedAdminUpdate() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      toastPromise(
        async ()=>{
          var updateData = {
            CompanyID: company.companyData?.code,
            SubmissionIDs: rows.map(r => r.id),
            NewAdmin: assignedAdmin
          };

          var res = await updateAssignee(updateData);

          if (!res.data) {
            throw 'Could not update at this time.';
          }

          await getCompanySubmissions();
        },
        false,
        `Updating assigned preparer...`,
        `Successfully assigned!`
      );
    }
  }

  function getBusinessLegalFromAnswers(answers) {
    if (!answers)
      return "-";

    const list = answers.filter(q => q.Text == "Business Legal name");

    if (list && list.length == 1)
      return list[0].Answer;
    else
      return "-";
  }

  function getNameFromAnswers(answers) {
    const businessName = getBusinessLegalFromAnswers(answers);

    if (businessName && businessName !== "-")
      return businessName;

    if (!answers)
      return "-";
    
    const list = answers.filter(q => q.Text == "First Name" || q.Text == "Last Name");

    if (list && list.length == 2) {
      var str = " ";

      list.forEach(element => {
        if (element.Text == "First Name") {
          str = element.Answer + str;
        } else {
          str = str + element.Answer;
        }
      });

      return str;
    } else {
      const fullName = answers.filter(q => q.Text == "Name of Submitter");

      if (fullName && fullName.length == 1) {
        return fullName[0].Answer;
      } else {
        return "-";
      }
    }
  }

  function getEmailFromAnswers(answers) {
    if (!answers)
      return "-";

    const list = answers.filter(q => q.Text == "Email");

    if (list && list.length == 1)
      return list[0].Answer;
    else
      return "-";
  }

  function getMobileNumberFromAnswers(answers) {
    if (!answers)
      return "-";

    const list = answers.filter(q => q.Text == "Mobile Number");

    if (list && list.length == 1) {
      const number = list[0].Answer.replace(/\D/g, '');
      
      // Check if we have a 10-digit number
      if (number && number.length === 10) {
        return `(${number.slice(0,3)}) ${number.slice(3,6)}-${number.slice(6)}`;
      }

      return number; // Return original if not 10 digits
    }
    else
      return "-";
  }
  
  function handleDocumentReceived(document, isChecked) {
    // prep an array for the new received docs.
    const previousDocs = viewDetailSubmission.ReceivedDocuments !== undefined ? [...viewDetailSubmission.ReceivedDocuments] : [];
    const updatedReceivedDocs = [];
    
    if (isChecked) {
      updatedReceivedDocs.push(...previousDocs, document);
    } else {
      var prior = previousDocs.filter(doc => doc !== document)
      updatedReceivedDocs.push(...prior);
    }
  
    toastPromise(
      async () => {
        // Update local states immediately for responsive UI
        setViewDetailSubmission(prev => ({
          ...prev,
          ReceivedDocuments: updatedReceivedDocs
        }));
  
        setSubmissions(prevSubmissions => 
          prevSubmissions.map(sub => 
            sub.id === viewDetailSubmission.id 
              ? { ...sub, ReceivedDocuments: updatedReceivedDocs }
              : sub
          )
        );
  
        // Update server
        const res = await updatedReceivedSubmissionDocs({
          CompanyID: company.companyData.code,
          SubmissionIDs: [viewDetailSubmission.id],
          ReceivedDocs: updatedReceivedDocs
        });
  
        if (!res.data) {
          throw 'Could not update documents at this time.';
        }
      },
      false,
      `Updating document status...`,
      `Document status updated!`
    );
  }

  function render() {
    return (
      <div className="container-fluid" style={{ paddingLeft: '5%', paddingRight: '5%', paddingTop: '2vh' }}>
        <div className="row">
          <div className='col-12' style={{ marginBottom: 8, marginTop: 8, display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
            <button className="btn btn-sm btn-primary" type="button" onClick={() => setShowResend(!showResend)}>Resend Selected</button>

            {
              !company.companyData?.extraFeatures?.sharableStatus ? null :
              <div className='input-group' style={{ width: "fit-content" }}>
                <select className="form-select form-select-sm" value={newStatus} onChange={v=>setNewStatus(v.target.value)}>
                  <option value={"SubmitDocs"}>Submitting Docs</option>
                  <option value={"Submitted"}>Submitted</option>
                  <option value={"Preparing"}>Preparing</option>
                  <option value={"Review"}>Review</option>
                  <option value={"Filing"}>Filing</option>
                  <option value={"Complete"}>Complete</option>
                </select>

                <button className="btn btn-sm btn-primary" type="button" onClick={() => statusUpdate()}>Update Selected</button>
              </div> 
            }

            {
              !company.companyData?.extraFeatures?.assignAdmins ? null :
              <div className='input-group' style={{ width: "fit-content" }}>
                <select className="form-select form-select-sm" value={assignedAdmin} onChange={v=>setAssignedAdmin(v.target.value)}>
                  <option value={""}>None</option>
                  {
                    company.admins?.map((a, i) => {
                      return <option value={a.Name} key={`admin_${i}`}>{a.Name}</option>
                    })
                  }
                </select>

                <button className="btn btn-sm btn-primary" type="button" onClick={() => AssignedAdminUpdate()}>Assign Selected</button>
              </div> 
            }

            <div className='input-group input-group-sm' style={{ width: "fit-content" }}>
              <DateTimePicker className={"rounded"} onChange={setTestFromDate} value={testFromDate} disableClock={true} clearIcon={null} />
              <span className='input-group-text'>-</span>
              <DateTimePicker onChange={setTestToDate} value={testToDate} disableClock={true} clearIcon={null} />

              <button className="btn btn-sm btn-primary" type='button' onClick={getCompanySubmissions}>Search</button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div style={{ height: gridHeight, width: '100%', position: 'relative', marginTop: 12 }} className="ag-theme-balham">
              <AgGridReact
                ref={gridRef}
                rowData={submissions}
                columnDefs={columnDefs}
                suppressRowClickSelection={true}
                enableCellTextSelection={true}
                suppressMenuHide={true}
                rowSelection="multiple">
              </AgGridReact>
            </div>
          </div>

          {
            !showResend ? null :
            <ResendSubmissions resendCustom={resendNewEmail} resendAdmin={resendAdminEmail} resendUser={resendEmail} userEmail={user.user.email} close={() => setShowResend(false)} />
          }
        </div>

    
        { !viewDetailSubmission ? null : 
          <Modal
              isOpen={true}
              onRequestClose={() => setViewDetailSubmission(undefined)}
              contentLabel={`Submission Details - ${getNameFromAnswers(viewDetailSubmission.Questions)}`}
              portalClassName="SubmissionModal"
          >
              <div className="SubmissionModalHeader">Submission Details - {getNameFromAnswers(viewDetailSubmission.Questions)}</div>
              <div className="SubmissionModalContent" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <SubmissionDetails Submission={viewDetailSubmission} usesStatus={company.companyData?.extraFeatures?.sharableStatus} onDocumentReceived={handleDocumentReceived} />
              </div>
          </Modal>
        }
      </div>
    );
  }

  return render();
}

const styles = {
  filters: {
    backgroundColor: '#84C5FB',
    borderRadius: 10
  },
  actions: {
    border: '1px solid #A7A7A7',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  actionButton: {
    width: '80%',
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  actionInput: {
    width: '80%',
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}

export default Submissions;